document.addEventListener("DOMContentLoaded", () => {
  const closeIconSVG = `
    <svg width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M19.3 5.71a1 1 0 00-1.42-1.42L12 10.59 6.12 4.71A1 1 0 104.7 6.12L10.59 12l-5.88 5.88a1 1 0 001.42 1.42L12 13.41l5.88 5.88a1 1 0 001.42-1.42L13.41 12l5.88-5.88z"/>
    </svg>
  `;

  const searchIconSVG = `
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4854 10.0629H10.7627L10.5066 9.81589C11.4031 8.77301 11.9428 7.4191 11.9428 5.94625C11.9428 2.66209 9.28071 0 5.99655 0C2.71239 0 0.050293 2.66209 0.050293 5.94625C0.050293 9.23042 2.71239 11.8925 5.99655 11.8925C7.46939 11.8925 8.82331 11.3528 9.86619 10.4563L10.1132 10.7124V11.4351L14.6872 16L16.0503 14.6369L11.4854 10.0629ZM5.99655 10.0629C3.71867 10.0629 1.87991 8.22413 1.87991 5.94625C1.87991 3.66838 3.71867 1.82962 5.99655 1.82962C8.27442 1.82962 10.1132 3.66838 10.1132 5.94625C10.1132 8.22413 8.27442 10.0629 5.99655 10.0629Z" fill="currentColor"></path>
    </svg>
  `;

  const searchOverlayHTML = `
    <div class="content-search-overlay__overlay">
      <div class="content-search-overlay__overlay-top">
        <div class="content-search-overlay__overlay-container">
          <input type="text" class="content-search-overlay__term" placeholder="What are you looking for?" autocomplete="off">
          <button class="content-search-overlay__close" aria-label="Close Search">
            ${closeIconSVG}
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  `;

  const resultsContainerHTML = `
    <div class="content-search-overlay__results-container">
      <div class="content-search-overlay__results"></div>
    </div>
  `;

  const navLevelElements = document.querySelectorAll(
    ".nav-1-level-nav, .nav-2-level-nav-main-links-bottom, .nav-2-level-nav-w-utility-nav-social-media, .nav-4-2-level-nav-w-utility-nav-certifications, .nav-2-level-nav-logo-centered-w-utility-nav"
  );

  navLevelElements.forEach((navLevelElement) => {
    let navType;

    if (
      navLevelElement.classList.contains(
        "nav-2-level-nav-w-utility-nav-social-media"
      )
    ) {
      navType = "nav-3";
    } else if (navLevelElement.classList.contains("nav-1-level-nav")) {
      navType = "nav-1";
    } else if (
      navLevelElement.classList.contains("nav-2-level-nav-main-links-bottom")
    ) {
      navType = "nav-2";
    } else if (
      navLevelElement.classList.contains(
        "nav-4-2-level-nav-w-utility-nav-certifications"
      )
    ) {
      navType = "nav-4";
    } else if (
      navLevelElement.classList.contains(
        "nav-2-level-nav-logo-centered-w-utility-nav"
      )
    ) {
      navType = "nav-5";
    } else {
      navType = "unknown";
    }

    function isMobile() {
      return window.innerWidth <= 768;
    }

    function isTablet() {
      return window.innerWidth > 768 && window.innerWidth <= 1080;
    }

    let navBarElement;

    if (
      (navType === "nav-3" && isMobile()) ||
      (navType === "nav-4" && isTablet()) ||
      (navType === "nav-4" && isMobile()) ||
      navType === "nav-5"
    ) {
      navBarElement = navLevelElement.querySelector(
        ".content-search-overlay__results-hook"
      );
    } else {
      navBarElement = navLevelElement.querySelector(".tms-main-nav-wrapper");
    }

    if (!navBarElement) {
      return;
    }

    if (navBarElement) {
      navBarElement.insertAdjacentHTML("beforeend", searchOverlayHTML);
    } else {
      return;
    }

    const resultsContainerParent = navLevelElement.querySelector(
      ".content-search-overlay__results-hook"
    );

    if (resultsContainerParent) {
      resultsContainerParent.insertAdjacentHTML(
        "beforeend",
        resultsContainerHTML
      );
    } else {
      return;
    }

    const openButtons = navLevelElement.querySelectorAll(
      ".content-search-overlay__trigger"
    );
    const closeButton = navBarElement.querySelector(
      ".content-search-overlay__close"
    );
    const searchOverlay = navBarElement.querySelector(
      ".content-search-overlay__overlay"
    );
    const searchField = navBarElement.querySelector(
      ".content-search-overlay__term"
    );
    const resultsDiv = resultsContainerParent.querySelector(
      ".content-search-overlay__results"
    );
    const resultsContainer = resultsContainerParent.querySelector(
      ".content-search-overlay__results-container"
    );

    let isOverlayOpen = false;
    let isSpinnerVisible = false;
    let previousValue;
    let typingTimer;

    openButtons.forEach((button) => {
      button.innerHTML = `
        <span class="content-search-overlay__icon content-search-overlay__icon--search">
          ${searchIconSVG}
        </span>
        <span class="content-search-overlay__icon content-search-overlay__icon--close">
          ${closeIconSVG}
        </span>
        <span class="content-search-overlay__label">Search</span>
      `;
      button.addEventListener("click", (e) => {
        e.preventDefault();
        if (isOverlayOpen) {
          closeOverlay();
        } else {
          openOverlay();
        }
      });
    });

    closeButton.addEventListener("click", closeOverlay);

    searchField.addEventListener("keyup", typingLogic);

    function typingLogic() {
      if (searchField.value !== previousValue) {
        clearTimeout(typingTimer);

        if (searchField.value) {
          if (!isSpinnerVisible) {
            resultsDiv.innerHTML = '<div class="spinner-loader"></div>';
            isSpinnerVisible = true;
          }
          typingTimer = setTimeout(getResults, 750);
        } else {
          resultsDiv.innerHTML = "";
          isSpinnerVisible = false;
        }
      }
      previousValue = searchField.value;
    }

    function getResults() {
      fetch(
        `/wp-json/tms/v1/search?term=${encodeURIComponent(searchField.value)}`
      )
        .then((response) => response.json())
        .then((results) => {
          resultsDiv.innerHTML = generateResultsHTML(results);
          isSpinnerVisible = false;
        })
        .catch((error) => {
          resultsDiv.innerHTML = `<p>An error occurred while fetching search results.</p>`;
          isSpinnerVisible = false;
        });
    }

    function generateResultsHTML(results) {
      let html = "";
      if (results.length) {
        html = '<ul class="content-search-overlay__results-list">';
        results.forEach((item) => {
          html += `
            <li>
              <a href="${item.permalink}">
                <h3>${item.title}</h3>
              </a>
              <p>${item.content}</p>
            </li>
          `;
        });
        html += "</ul>";
      } else {
        html = `<p class="content-search-overlay__results-null">No results found.</p>`;
      }
      return html;
    }

    function openOverlay() {
      searchOverlay.classList.add("content-search-overlay__overlay--active");
      resultsContainer.classList.add(
        "content-search-overlay__results-container--active"
      );
      searchField.value = "";
      setTimeout(() => searchField.focus(), 301);
      isOverlayOpen = true;

      navLevelElement.classList.add("tms-nav--active");

      const hookHeight = resultsContainerParent.offsetHeight;
      resultsContainer.style.top = hookHeight + "px";

      if (navType === "nav-1") {
        navLevelElement.classList.add("nav-1-level-nav--active");

        if (isTablet()) {
          const navHeight = navLevelElement.offsetHeight;
          const overlayHeight = searchOverlay.offsetHeight;

          searchOverlay.style.top = navHeight + "px";
          resultsContainer.style.top = navHeight + overlayHeight + "px";
        }
      }
      if (navType === "nav-5") {
        navLevelElement.classList.add(
          "nav-2-level-nav-logo-centered-w-utility-nav--active"
        );
      }

      document.addEventListener("click", handleClickOutside);

      openButtons.forEach((button) => {
        button.classList.add("is-active");

        const label = button.querySelector(".content-search-overlay__label");
        if (label) label.textContent = "Close";

        if (isTablet()) {
          button.setAttribute("aria-label", "Close Search");
          if (label) label.textContent = "Close";
        }

        const screenReaderText = button.querySelector(".screen-reader-text");
        if (screenReaderText) {
          screenReaderText.textContent = "Close Search";
        }
      });
    }

    function closeOverlay() {
      searchOverlay.classList.remove("content-search-overlay__overlay--active");
      resultsContainer.classList.remove(
        "content-search-overlay__results-container--active"
      );
      isOverlayOpen = false;

      navLevelElement.classList.remove("tms-nav--active");

      if (navType === "nav-1") {
        navLevelElement.classList.remove("nav-1-level-nav--active");

        if (isTablet()) {
          searchOverlay.style.top = "";
          resultsContainer.style.top = "";
        }
      }
      if (navType === "nav-5") {
        navLevelElement.classList.remove(
          "nav-2-level-nav-logo-centered-w-utility-nav--active"
        );
      }

      searchField.value = "";
      resultsDiv.innerHTML = "";
      isSpinnerVisible = false;

      resultsContainer.style.top = "";

      document.removeEventListener("click", handleClickOutside);

      openButtons.forEach((button) => {
        button.classList.remove("is-active");

        const label = button.querySelector(".content-search-overlay__label");
        if (label) label.textContent = "Search";

        if (isTablet()) {
          button.setAttribute("aria-label", "Search");
          if (label) label.textContent = "Search";
        }

        const screenReaderText = button.querySelector(".screen-reader-text");
        if (screenReaderText) {
          screenReaderText.textContent = "Search";
        }
      });
    }

    function handleClickOutside(event) {
      if (
        !searchOverlay.contains(event.target) &&
        !resultsContainer.contains(event.target) &&
        !Array.from(openButtons).some((button) => button.contains(event.target))
      ) {
        closeOverlay();
      }
    }

    window.addEventListener("resize", () => {
      if (isOverlayOpen) {
        const hookHeight = resultsContainerParent.offsetHeight;
        resultsContainer.style.top = hookHeight + "px";

        if (navType === "nav-1") {
          if (isTablet()) {
            const navHeight = navLevelElement.offsetHeight;
            const overlayHeight = searchOverlay.offsetHeight;
            searchOverlay.style.top = navHeight + "px";
            resultsContainer.style.top = navHeight + overlayHeight + "px";
          } else {
            searchOverlay.style.top = "";
            resultsContainer.style.top = "";
          }
        }

        if (navType === "nav-3") {
          if (isMobile()) {
            navBarElement = navLevelElement.querySelector(
              ".content-search-overlay__results-hook"
            );
          } else {
            navBarElement = navLevelElement.querySelector(
              ".tms-main-nav-wrapper"
            );
          }
        }
      }
    });
  });
});
