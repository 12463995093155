document.addEventListener("DOMContentLoaded", function () {
  const mapWrapper = document.querySelector(".content-clickable-global-map");

  if (!mapWrapper) {
    return;
  }

  const svgWrapper = mapWrapper.querySelector(
    ".content-clickable-global-map__svg-wrapper"
  );
  const sidebar = mapWrapper.querySelector(
    ".content-clickable-global-map__sidebar"
  );
  const closeButton = sidebar
    ? sidebar.querySelector(".content-clickable-global-map__sidebar-close")
    : null;
  const sidebarContentContainer = sidebar
    ? sidebar.querySelector(".content-clickable-global-map__sidebar-content")
    : null;

  const continents = [
    "content-clickable-global-map__svg-north-america",
    "content-clickable-global-map__svg-south-america",
    "content-clickable-global-map__svg-europe",
    "content-clickable-global-map__svg-africa",
    "content-clickable-global-map__svg-asia",
    "content-clickable-global-map__svg-oceania",
  ];

  const continentsWithEntries = mapWrapper.dataset.continentsWithEntries
    ? mapWrapper.dataset.continentsWithEntries.split(",")
    : [];

  function isMobile() {
    return window.innerWidth <= 768;
  }

  function initializeDesktopMap() {
    if (isMobile()) return;

    continents.forEach((continentId) => {
      const continentElement = document.getElementById(continentId);

      if (continentElement) {
        const hasEntries = continentsWithEntries.includes(continentId);

        if (hasEntries) {
          continentElement.classList.add("has-entries");

          continentElement.addEventListener("click", function () {
            if (!sidebarContentContainer) return;

            const allContinentEntries =
              sidebarContentContainer.querySelectorAll(
                ".content-clickable-global-map__continent-entries"
              );
            allContinentEntries.forEach((entry) => {
              entry.classList.remove(
                "content-clickable-global-map__continent-entries--active"
              );
              entry.classList.add(
                "content-clickable-global-map__continent-entries--inactive"
              );
            });

            const continentEntries = sidebarContentContainer.querySelector(
              `.content-clickable-global-map__continent-entries[data-continent="${continentId}"]`
            );
            if (continentEntries) {
              continentEntries.classList.remove(
                "content-clickable-global-map__continent-entries--inactive"
              );
              continentEntries.classList.add(
                "content-clickable-global-map__continent-entries--active"
              );
            }

            if (sidebar) {
              sidebar.classList.remove(
                "content-clickable-global-map__sidebar--closed"
              );
              sidebar.classList.add(
                "content-clickable-global-map__sidebar--open"
              );
            }

            if (svgWrapper) {
              const allContinents = svgWrapper.querySelectorAll(".has-entries");
              allContinents.forEach((continent) => {
                continent.classList.remove(
                  "content-clickable-global-map__svg--active"
                );
              });

              continentElement.classList.add(
                "content-clickable-global-map__svg--active"
              );
              svgWrapper.classList.add(
                "content-clickable-global-map__svg-wrapper--active"
              );
            }
          });
        } else {
          continentElement.classList.add("no-entries");
        }
      }
    });

    if (closeButton) {
      closeButton.addEventListener("click", function () {
        if (!sidebar) return;

        sidebar.classList.remove("content-clickable-global-map__sidebar--open");
        sidebar.classList.add("content-clickable-global-map__sidebar--closed");

        if (svgWrapper) {
          const allContinents = svgWrapper.querySelectorAll(".has-entries");
          allContinents.forEach((continent) => {
            continent.classList.remove(
              "content-clickable-global-map__svg--active"
            );
          });

          svgWrapper.classList.remove(
            "content-clickable-global-map__svg-wrapper--active"
          );
        }

        if (sidebarContentContainer) {
          const allContinentEntries = sidebarContentContainer.querySelectorAll(
            ".content-clickable-global-map__continent-entries"
          );
          allContinentEntries.forEach((entry) => {
            entry.classList.remove(
              "content-clickable-global-map__continent-entries--active"
            );
            entry.classList.add(
              "content-clickable-global-map__continent-entries--inactive"
            );
          });
        }
      });
    }
  }

  function initializeMobileAccordion() {
    if (!isMobile()) return;

    const accordionItems = mapWrapper.querySelectorAll(
      ".content-clickable-global-map__accordion-item"
    );

    accordionItems.forEach((item) => {
      const header = item.querySelector(
        ".content-clickable-global-map__accordion-header"
      );
      const content = item.querySelector(
        ".content-clickable-global-map__accordion-content"
      );

      if (!header || !content) return;

      header.addEventListener("click", function () {
        const isExpanded = item.classList.contains("is-expanded");

        accordionItems.forEach((otherItem) => {
          otherItem.classList.remove("is-expanded");
          const otherContent = otherItem.querySelector(
            ".content-clickable-global-map__accordion-content"
          );
          if (otherContent) {
            otherContent.style.maxHeight = null;
          }
          const otherHeader = otherItem.querySelector(
            ".content-clickable-global-map__accordion-header"
          );
          if (otherHeader) {
            otherHeader.classList.remove("is-expanded");
          }
        });

        if (!isExpanded) {
          item.classList.add("is-expanded");
          content.style.maxHeight = content.scrollHeight + "px";
          if (header) {
            header.classList.add("is-expanded");
          }
        }
      });
    });
  }

  function initialize() {
    initializeDesktopMap();
    initializeMobileAccordion();
  }

  initialize();

  let resizeTimeout;
  window.addEventListener("resize", function () {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(function () {
      removeEventListeners();
      initialize();
    }, 200);
  });

  function removeEventListeners() {
    continents.forEach((continentId) => {
      const continentElement = document.getElementById(continentId);
      if (continentElement) {
        const clone = continentElement.cloneNode(true);
        if (continentElement.parentNode) {
          continentElement.parentNode.replaceChild(clone, continentElement);
        }
      }
    });

    if (closeButton && closeButton.parentNode) {
      const newCloseButton = closeButton.cloneNode(true);
      closeButton.parentNode.replaceChild(newCloseButton, closeButton);
    }

    const accordionItems = mapWrapper.querySelectorAll(
      ".content-clickable-global-map__accordion-item"
    );
    accordionItems.forEach((item) => {
      const header = item.querySelector(
        ".content-clickable-global-map__accordion-header"
      );
      if (header) {
        const newHeader = header.cloneNode(true);
        header.parentNode.replaceChild(newHeader, header);
      }
    });
  }
});
